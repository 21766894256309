import { Injectable } from "@angular/core";
import { UserInfo } from "@models/data/user-info";
import { UserProperties } from "@models/enums/user-properties";
import { LanguagesService } from "@shared/services/languages.service";

@Injectable({
  providedIn: "root",
})
export class LoadUserInfoService {
  constructor(private languagesService: LanguagesService) {}

  loadUserInfos(userInfo: UserInfo) {
    const properties = userInfo.properties;
    const preferLanguage: string | undefined =
      properties[UserProperties.prefer_language];
    if (preferLanguage) {
      this.languagesService.setLanguage(preferLanguage);
    }
  }
}
