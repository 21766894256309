import { Injectable } from "@angular/core";
import { User } from "@models/data/user";
import { UserProperties } from "@models/enums/user-properties";
import { GraphqlService } from "@shared/graphql/graphql.service";
import { Apollo, gql } from "apollo-angular";
import { Observable, shareReplay, switchMap } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class UserPropertiesService {
  constructor(
    private apollo: Apollo,
    private graphqlService: GraphqlService,
  ) {}

  updateProperty(key: UserProperties, value: string | null): Observable<void> {
    return this.getCurrentUser().pipe(
      map((user) => {
        //user.properties[key] = value;
        return user;
      }),
      switchMap((user) => this.graphqlService.update<User>(user, "User")),
      map(() => {
        return;
      }),
    );
  }

  private getCurrentUser(): Observable<User> {
    return this.apollo
      .query<{ me: User }>({
        query: gql`
          query me {
            me {
              id
              firstName
              lastName
              email
              possedeRoles {
                id
                __typename
              }
              sessions {
                start
                sessionId
                lastAccess
                ipAddress
                __typename
              }
              properties
              __typename
            }
          }
        `,
        fetchPolicy: "no-cache",
      })
      .pipe(
        map(({ data }) => data.me),
        shareReplay(1, 200),
      );
  }

  getProperties(): Observable<Record<UserProperties, string | null>> {
    return this.getCurrentUser().pipe(
      map((user) => {
        return {} as Record<UserProperties, string | null>;
      }),
    );
  }
}
